import { Controller } from '@hotwired/stimulus'
import SmoothScroll from 'smooth-scroll'

export default class extends Controller {
  static targets = ['container', 'intro', 'overview', 'features', 'facilities', 'reviews']

  initialize () {
    this.scroll = new SmoothScroll(null, {
      offset: 55,
      speed: 600,
      speedAsDuration: true,
      easing: 'linear',
      updateURL: false,
      popstate: false,
    })

    let lastScrollPosition = 0
    let ticking = false

    window.addEventListener('scroll', () => {
      lastScrollPosition = window.scrollY

      if (!ticking) {
        window.requestAnimationFrame(() => {
          this.toggleVisibility(lastScrollPosition)
          ticking = false
        })

        ticking = true
      }
    })

    if ('IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype) {
      this.doWhenIntersect(document.querySelector('#intro'), this.introTarget)
      this.doWhenIntersect(document.querySelector('#overview'), this.overviewTarget)
      this.doWhenIntersect(document.querySelector('#features'), this.featuresTarget)
      this.doWhenIntersect(document.querySelector('#facilities'), this.facilitiesTarget)
      this.doWhenIntersect(document.querySelector('#reviews'), this.reviewsTarget)
    }
  }

  className () {
    return this.element.dataset.classname
  }

  doWhenIntersect (targetSection, targetTab) {
    if (!targetSection) return

    const callback = entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          targetTab.classList.add(`${this.className()}::active`)
        } else {
          targetTab.classList.remove(`${this.className()}::active`)
        }
      })
    }

    const observer = new IntersectionObserver(callback, {
      rootMargin: '0px',
      threshold: 0.2,
    })
    observer.observe(targetSection)
  }

  toggleVisibility (scrollPosition) {
    if (scrollPosition > 400) {
      this.containerTarget.classList.add(`${this.className()}::visible`)
    } else {
      this.containerTarget.classList.remove(`${this.className()}::visible`)
    }
  }

  scrollTo (id) {
    const anchor = document.querySelector(id)
    this.scroll.animateScroll(anchor)
  }

  scrollToIntro () {
    this.scrollTo('#intro')
  }

  scrollToOverview () {
    this.scrollTo('#overview')
  }

  scrollToFeatures () {
    this.scrollTo('#features')
  }

  scrollToFacilities () {
    this.scrollTo('#facilities')
  }

  scrollToReviews () {
    this.scrollTo('#reviews')
  }
}
